<template>
    <v-app id="main-app" style="overflow: hidden; background-color: #f2f6fe">
    <v-row class="d-flex align-center justify-center fill-height">
        <v-card width="400">
            <v-alert
                v-if="alertSuccess"
                dense
                text
                type="success"
                >
                Email sent successfully!
            </v-alert>
            <v-alert
                v-if="alertError"
                dense
                text
                type="error"
                >
                Email could not be sent!
            </v-alert>
            <v-card-title class="primary--text">
                Find your account via email!
            </v-card-title>
            <div style="width: 100%; height: 2px; background-color: #f2f6fe;" />
            <v-form v-model="valid">
            <v-text-field class="mt-8 mb-2 mx-8" :rules="emailRules" v-model="email" type="email" outlined label="Your email">
            </v-text-field>
            <div style="width: 100%; height: 2px; background-color: #f2f6fe;" />
            <v-card-actions class="my-3">
                <v-spacer />
            <v-btn color="red" text @click="$router.push({name: 'Login'})">Cancel</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="sendEmail">Send email</v-btn>
            </v-card-actions>
            </v-form>
        </v-card>
    </v-row>
    </v-app>
</template>
<script>
export default {
    data () {
        return {
            email: '',
            valid: false,
            alertError: false,
            alertSuccess: false
        }
    },
    computed: {
        emailRules() {
            return [
                (v) => !!v || 'E-mail is required',
                (v) => /^\w+([/.-]?\w+)*@\w+([/.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            ]
        },
    },
    methods: {
        async sendEmail() {
            const body = {
                email: this.email
            }
            await this.$store.dispatch('users/sendValidationLinkByEmail',body).then(() => {
                this.alertError = false
                this.alertSuccess = true
            }).catch(() => {
                this.alertSuccess = false
                this.alertError = true
            })
        }
    }
}
</script>